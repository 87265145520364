<template>
  <UserStyles>
    <v-main>
      <v-card
        class="ma-2"
        flat
      >
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Склад</v-card-title>
          </v-col>
          <v-col cols="12" md="4" class="text-right">
            <router-link :to="{ name: 'WarehouseEdit', params: { id: warehouse.id } }">
              <v-btn color="primary">Изменить</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="account-text-info">
                <span class="font-weight-medium">Название:</span>
                {{ warehouse.name }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Компания: </span>
                <router-link :to="{ name: 'CompanyView', params: { id: warehouse.company_id} }">
                  {{ warehouse.company_name }}
                </router-link>
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Город:</span>
                {{ warehouse.city }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </UserStyles>
</template>

<script>
import UserStyles from "@/views/users/UserStyles.vue";
import account from "@/api/account";

export default {
  name: "WarehouseView",
  components: {
    UserStyles
  },
  data() {
    return {
      warehouse: {
        id: '',
        name: '',
        city: '',
      },
    };
  },
  async created() {
    await this.fetchWarehouse();
  },
  methods: {
    async fetchWarehouse() {
      try {
        const warehouseId = this.$route.params.id;
        const response = await account.getWarehouse(warehouseId);
        this.warehouse = response.data;
        console.log('Warehouse:', this.warehouse);
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    },
  },
}
</script>

<style scoped>

</style>
